/**
 * Gestion menu navbar + header
 */
export function handleNav() {
  /*
  // gestion dropdown sur la souris
  const menuDisplay = function () {
    this.parentNode.getElementsByClassName('dropdown-menu')[0].classList.add('d-block')
  }
  const dTs = document.querySelectorAll('.dropdown-toggle') // ou .getElementsByClassName('dropdown-toggle')
  dTs.forEach(dT => {
    dT.addEventListener('mouseenter', menuDisplay)
    dT.addEventListener('click', menuDisplay)
    //dT.addEventListener('touchstart', menuDisplay)
  })


  // gestion mega menu
  const resizeMm = function () {
    const a = document.body.scrollWidth,
          i = 1199 // max viewport
    const dDm = this.querySelector('.dropdown-menu')
    //dDm.classList.add('d-block')
    //if (dDm.classList.contains('megamenu-full')) // présence d'un méga menu qui prend tout l'écran
    //  dDm.style.width = (document.body.scrollWidth - 60) + 'px'
    if (a + 30 > i) {
      dDm.style.left = ''
      const n = this.offsetLeft
      const r = this.getBoundingClientRect().width
      const s = document.querySelector('.navbar-collapse').getBoundingClientRect().left + window.scrollX
      const l = dDm.getBoundingClientRect().width
      let d = n + r / 2 - l / 2
      const c = a - s
      d + s < 0 ? d = 30 - s : d + l > c && (d = c - l - 30)
      dDm.style.left = d + 'px'
    } else {
      dDm.style.left = ''
      dDm.style.right = ''
    }
  }
  const mMs = document.getElementById('header').querySelectorAll('li.dropdown.megamenu')
  mMs.forEach(mM => {
    mM.addEventListener('mouseenter', resizeMm)
    mM.addEventListener('click', resizeMm)
    //mM.addEventListener('touchstart', resizeMm)
  })
  */

  // navbar toggler
  const toggleNav = function() {
    const nav = document.querySelector(this.getAttribute('data-trigger'))
    this.classList.toggle('open')
    document.body.classList.toggle('open')
    document.getElementById('header').classList.toggle('open')
    nav.classList.toggle('show')
    document.querySelector('.navbar-overlay').classList.toggle('visible')
  }
  document.querySelectorAll('[data-trigger]').forEach(el => {
    el.addEventListener('click', toggleNav)
  })
  /*
  const closeNav = function() {
    document.querySelectorAll('.navbar-collapse').forEach(el => {
      el.classList.remove('show')
    })
    document.querySelector('.navbar-overlay').classList.remove('visible')
    document.body.classList.remove('open')
  }
  document.querySelectorAll('.navbar-close').forEach(el => {
    el.addEventListener('click', closeNav)
  })
  document.querySelector('.navbar-overlay').addEventListener('click', closeNav)
  */
}

/**
 * Sticky header - only on scrollUp
 */
/*
var lastScrollTop = 0
export function sticky () {
  const h = document.getElementById('header'),
        s = 'sticky',
        //b = document.body,
        w = document.querySelector('html')
        //bp = 1199 - 40

  const st = window.pageYOffset || document.documentElement.scrollTop // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
   if (st > lastScrollTop) {
     h.classList.remove(s)
   } else if (st < lastScrollTop) {
     //if ((w.scrollTop > 64 && w.offsetWidth > bp) || (w.scrollTop > 80 && w.offsetWidth <= bp))
     if (w.scrollTop > 100)
       h.classList.add(s)
     else
       h.classList.remove(s)
   } // else was horizontal scroll
   lastScrollTop = st <= 0 ? 0 : st // For Mobile or negative scrolling
}
*/

/**
 * Sticky header
 */
export function sticky () {
  const h = document.getElementById('header'),
        s = 'sticky',
        //b = document.body,
        w = document.querySelector('html')
        //bp = 1199 - 40

   //if ((w.scrollTop > 64 && w.offsetWidth > bp) || (w.scrollTop > 80 && w.offsetWidth <= bp))
   if (w.scrollTop > 100)
     h.classList.add(s)
   else
     h.classList.remove(s)
}



/**
 * Reveal function
 */
export function reveal() {
  document.querySelectorAll('.reveal').forEach(el => {
    const windowHeight = window.innerHeight
    const elementTop = el.getBoundingClientRect().top
    const elementVisible = 150
    if (elementTop < windowHeight - elementVisible) {
      el.classList.add('active')
    } else {
      el.classList.remove('active')
    }
  })
}
