/* fichier JS de base commun quelques soient les versions du projet web */
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
import 'lazysizes/plugins/aspectratio/ls.aspectratio' // lazy loading

// Styles
import './scss/bootstrap' // bootstrap => customisez bootstrap directement

// Scripts librairies
import { docReady, loading } from './js/libs/utils'
import { handleNav, sticky, reveal } from './js/libs/misc'

document.body.classList.remove('no-js')

// Scroll to top
function getScrollToTop () {
  import(/* webpackChunkName: "scrollToTop" */ './js/components/scrollToTop.js')
}

// lazysizes : add simple support for background images
document.addEventListener('lazybeforeunveil', e => {
  const bg = e.target.getAttribute('data-background')
  if (bg) e.target.style.backgroundImage = 'url(' + bg + ')'
  // css elements
  const cssEl = e.target.getAttribute('data-css-element')
  if (cssEl) loadCss(cssEl)
  // js elements
  const jsEl = e.target.getAttribute('data-js-element')
  if (jsEl) loadJs(jsEl, e.target)
})

// Css elements
function loadCss (el) {
  import(/* webpackChunkName: "[request]" */ `./scss/components/${el}.scss`)
}

// Js elements
function loadJs (el, target) {
  loading(target)
  import(/* webpackChunkName: "[request]" */ `./js/components/${el}.js`).then(() => {
    loading(target, false)
  })
}

// Book Modal
function getBookModal () {
  import(/* webpackChunkName: "bookModal" */ './js/components/bookModal.js')
}

// Click and Collect Modal
function getClickAndCollectModal () {
  import(/* webpackChunkName: "bookModal" */ './js/components/clickAndCollectModal.js')
}

// Tooltips
function getTooltips () {
  import(/* webpackChunkName: "tooltips" */ './js/components/tooltips.js')
}

// Actions principales communes à toutes les pages
docReady(function () {
  // scroll
  document.addEventListener('scroll', () => {
    if (document.querySelector('html').scrollTop >= 220 && !document.getElementById('scrollUp')) { getScrollToTop() }
    sticky()
    reveal()
  })

  // boutons liens
  document.querySelectorAll('[data-toggle="link"]').forEach(link => {
    link.addEventListener('click', e => {
      const target = e.currentTarget.getAttribute('data-target')
      const href = e.currentTarget.getAttribute('data-href')
      if (target && target === 'self') { document.location.href = href } else { window.open(href) }
    })
  })

  // anchors
  document.querySelectorAll('.scroll').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault()
      const target = document.querySelector(this.getAttribute('target'))
      if (target) { target.scrollIntoView({ behavior: 'smooth' }) }
    })
  })

  // bouton réservation
  document.querySelectorAll('.book-table-lts').forEach(btn => {
    btn.addEventListener('click', function (e) {
      e.preventDefault()
      getBookModal()
    })
  })

  // bouton click & collect
  document.querySelectorAll('.click-and-collect-lts').forEach(btn => {
    btn.addEventListener('click', function (e) {
      e.preventDefault()
      getClickAndCollectModal()
    })
  })

  // tooltips
  document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(tt => {
    tt.addEventListener('mouseover', function () {
      getTooltips()
    })
  })

  handleNav()
  sticky()
  reveal()
})
