// Scripts librairies
import { docReady } from './js/libs/utils'

// Global styles
import './scss/custom_light'

// Charge la base commune
require('./common.js')

docReady(function () {
  console.log('Homepage template')
})
